<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                 
                    <!-- Section Detail Start -->

                    <!--information_general start-->
                    <div class="content-onglets table" v-if="key == 'detail_general_information' && item.show">
                        <table class="comparateur2 text-left border-top-gray">
                            <tr v-if="data['ma_date']">
                                <td class="text-left bold"><strong> {{$t('Date_AMM')}} </strong></td>
                                <td class="text-left"> {{DDMMMYY(data['ma_date'])}} </td>
                            </tr>
                            <tr v-if="data.inami['reimbursement_condition']">
                                <td class="text-left bold"><strong> {{$t('Reimbursement condition')}} </strong></td>
                                <td class="text-left"> {{$t('reimbursement_condition_'+data.inami['reimbursement_condition'])}} </td>
                            </tr>
                            <tr v-if="data.inami['reimbursement_category']">
                                <td class="text-left bold"><strong> {{$t('Reimbursement category')}} </strong></td>
                                <td class="text-left"> {{$t('reimbursement_category_'+data.inami['reimbursement_category'])}} </td>
                            </tr>
                        </table> 
                    </div>                    
                    <!--information_general end--> 

                    <!--MA Reimbursed indication start-->
                    <div class="content-onglets" v-else-if="key == 'detail_reimbursed_indication' && item.show">
                        <p v-if="$i18n.locale == 'fr'" v-html="data.inami['reimbursed_indication_fr']"></p> 
                        <p v-else v-html="data.inami['reimbursed_indication_en']"></p>                      
                    </div>    
                    <!--MA Reimbursed indication end-->

                    <!--Indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show"/>         
                    <!--Indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!--Background start-->
                    <div class="content-onglets" v-else-if="key == 'detail_background' && item.show" >
                        <p v-html="data.inami['background']"></p>
                    </div>
                    <!--Background end-->

                     <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!--Clinical effectiveness start-->
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_effectiveness' && item.show" >
                        <p v-html="data.inami['clinical_effectiveness']"></p>
                    </div>
                    <!--Clinical effectiveness end-->

                    <!--Safety start-->
                    <div class="content-onglets" v-else-if="key == 'detail_safety' && item.show" >
                        <p v-html="data.inami['safety']"></p>
                    </div>
                    <!--Safety end-->

                    <!--Cost-effectiveness start-->
                    <div class="content-onglets" v-else-if="key == 'detail_cost_effectiveness' && item.show" >
                        <p v-html="data.inami['cost_effectiveness']"></p> 
                    </div>
                    <!--Cost-effectiveness end-->
                    
                    <!-- detail_EvaluationEconomic  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.inami.evaluation_economic" />

                    <!-- detail_EconomicEvaluation -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--budget impact start-->
                    <div class="content-onglets" v-else-if="key == 'detail_budget_impact' && item.show" >
                        <p v-html="data.inami['budget_impact']"></p> 
                    </div>
                    <!--budget impact end-->

                    <!--Conclusion start-->
                    <div class="content-onglets" v-else-if="key == 'detail_conclusion_text' && item.show" >
                        <p v-html="data.inami['conclusion']"></p> 
                    </div>
                    <!--Conclusion end-->

                    <!--  detail_EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_EssaisClinique' && item.show" :datas="data.essais_clinique"/>
                    <!--  detail_EssaisClinique end-->

                    <!--  detail_key_documents start-->
                    <KeyDocument  v-else-if="key == 'detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!--  detail_key_documents end-->

                    <!-- Section Detail End -->
                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default{
    name: 'inami',
    components : {
        LeftSection,
        TreatmentLine,
        Conclusion,
        EconomicEvaluation,
        EvaluationEconomic,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange                
                detail_general_information : { 'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},  
                detail_reimbursed_indication : {'title' : 'Reimbursed indication', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : { 'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},   
                treatment_line : { 'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_background : { 'title' : 'background', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_clinical_effectiveness : { 'title' : 'clinical_effectiveness', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_safety : { 'title' : 'safety', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_cost_effectiveness : { 'title' : 'cost_effectiveness', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_EvaluationEconomic : { 'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},           
                detail_EconomicEvaluation : { 'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},           
                detail_budget_impact : { 'title' : 'budget_impact', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_conclusion_text : { 'title' : 'conclusion', 'show' : true, 'enable' : false, 'orange_text':false},           
                detail_EssaisClinique : { 'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true},      
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },
            }
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.inami){
            
            if(this.data['ma_date'] || this.data.inami['reimbursement_condition'] || this.data.inami['reimbursement_category'])
            {
                this.list_check_box['detail_general_information'].enable = true
            }
            if((this.data['indication_fr'] && this.$i18n.locale === 'fr') || (this.data['indication_en'] && this.$i18n.locale !== 'fr'))
            {
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.inami['treatment_line'] || this.data.inami['treatment_specificity'] || this.data.inami['treatment_administration'] )
            {
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.inami['background'])
            {
                this.list_check_box['detail_background'].enable = true
            }
            if(this.data.inami['prioritis_hta_index'] || this.data.inami['rationale_commentary'])
            {
                this.list_check_box['detail_Conclusion'].enable = true
            }

            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }


            if(this.data.inami['clinical_effectiveness'])
            {
                this.list_check_box['detail_clinical_effectiveness'].enable = true
            }
            if(this.data.inami['safety'])
            {
                this.list_check_box['detail_safety'].enable = true
            }
            if(this.data.inami['cost_effectiveness'])
            {
                this.list_check_box['detail_cost_effectiveness'].enable = true
            }
                
            if(this.data.inami.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            }
            if(this.data.inami['budget_impact'])
            {
                this.list_check_box['detail_budget_impact'].enable = true
            }
            if(this.data.inami['conclusion']) 
            {
                this.list_check_box['detail_conclusion_text'].enable = true
            }      
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_EssaisClinique'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if((this.$i18n.locale == 'fr' && this.data.inami['reimbursed_indication_fr']) || (this.$i18n.locale != 'fr' && this.data.inami['reimbursed_indication_en']))
            {
                this.list_check_box['detail_reimbursed_indication'].enable = true
            }        

        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
         data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }  
    }, 
}
</script>

<style>

</style>
